import { StockState, StockActionType } from './stockTypes';

export const initialState: StockState = {
  productsStock: {
    stock: [],
  },
  stockLoading: true,
  stockError: false,
};

const stockReducer = (
  state = initialState,
  action: { type: string; payload?: any }
): StockState => {
  switch (action.type) {
    case StockActionType.FETCH_PRODUCTS_STOCK_SUCCEEDED:
      return { ...state, stockLoading: false, productsStock: action.payload.productsStock };

    case StockActionType.FETCH_PRODUCTS_STOCK_FAILED:
      return { ...state, stockError: true, stockLoading: false };

    default:
      return state;
  }
};

export default stockReducer;
