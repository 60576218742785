import classes from './Switcher.module.scss';
import { Channel, TvShow } from '../../types/tvShow';
import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { useEffect, useState } from 'react';
import { getShowsByChannel } from '../../utils/misc';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ClearVariants } from '../../redux/products/productsActions';
import Config from "../../config/config";
import cx from 'classnames';
import { CaretDownIcon, CaretUpIcon } from 'evergreen-ui';

interface ShowHeaderProps {
  showList: TvShow[];
  channel: Channel;
  currentProgram: TvShow;
}

const ShowHeader = ({ showList, channel, currentProgram }: ShowHeaderProps) => {
  const dispatch = useDispatch();

  const [isOpen, toggle] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: number }[]>([
    {
      label: '',
      value: 0,
    },
  ]);

  useEffect(() => {
    setOptions(getShowsByChannel(showList, channel));
  }, [showList, channel]);

  return (
    <div className={classes.select_container}>
      <h4>Select a show</h4>
      <Button 
        type={EButtonType.BLANK} 
        size={EButtonSize.SMALL} 
        icon={{ right: isOpen ? <CaretUpIcon /> : <CaretDownIcon /> }}
        onClick={() => toggle(prev => !prev)}
        className={classes.select_trigger}
      >{currentProgram?.title}</Button>
      {isOpen && (
        <div className={classes.select_wrapper}>
          {options.map(option => (
            <div className={cx({[classes.selected]: currentProgram.id === option.value})} onClick={e => {
              console.log('==', option.value);
              const currentProgram = showList.find((show: TvShow) => show.id === option.value);
              
              dispatch(new ClearVariants());
              currentProgram &&
                dispatch(
                  push(
                    `${Config.rootRoute}/show-reporting/${currentProgram.id}/?date_time=${currentProgram.date_time}&channel=${currentProgram.channel}`
                  )
                );
              toggle(false);
            }
              
            }>{option.label}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowHeader;
